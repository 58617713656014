const { t } = i18n.global;
import i18n from "@/core/plugins/i18n";

export enum ProductType {
  InternationalFlight = "InternationalFlight",
  DomesticFlight = "DomesticFlight",
  Visa = "Visa",
  InternationalHotel = "InternationalHotel",
  Tour = "Tour",
}
export const productTypes = {
  [ProductType.InternationalFlight]: t("international_flight"),
  // [ProductType.DomesticFlight]: t("domestic_flight"),
  [ProductType.Visa]: t("visa"),
  [ProductType.InternationalHotel]: t("hotel"),
  [ProductType.Tour]: t("tour"),
};

export enum ProductTypeNumber {
  InternationalFlight = 1,
  DomesticFlight = 2,
  InternationalHotel = 3,
  DomesticHotel = 4,
  Train = 5,
  Visa = 6,
}
export const productTypesNumber = {
  [ProductTypeNumber.InternationalFlight]: t("international_flight"),
  [ProductTypeNumber.DomesticFlight]: t("domestic_flight"),
  [ProductTypeNumber.InternationalHotel]: t("international_hotel"),
  [ProductTypeNumber.DomesticHotel]: t("domestic_hotel"),
  [ProductTypeNumber.Train]: t("train"),
  [ProductTypeNumber.Visa]: t("visa"),
};

export enum ProductTypeHotel {
  InternationalHotel = "InternationalHotel",
  DomesticHotel = "DomesticHotel",
}
export const productTypeHotel = {
  [ProductTypeHotel.InternationalHotel]: t("international_hotel"),
  [ProductTypeHotel.DomesticHotel]: t("domestic_hotel"),
};
