import { ProductType } from "@/core/models/ProductType";
import { Permission } from "@/core/models/permissions/permissions";

export function getBackOfficeRoutes(productType: ProductType) {
  return [
    {
      path: "user-log",
      name: productType + "user-log",
      meta: {
        productType,
        permission: Permission["InternationalFlight:Logs:View"],
      },
      component: () =>
        import("@/views/international-flight/user-log/UserLog.vue"),
    },
    {
      path: "cache-management",
      name: productType + "cache-management",
      component: () =>
        import(
          "@/views/international-flight/cache-management/FlightCacheManagement.vue"
        ),
    },
    {
      meta: {
        productType,
        permission: Permission["InternationalFlight:Reports:View"],
      },
      path: "reports/financial",
      name: productType + "financial",
      component: () => import("@/views/report/financial/index.vue"),
    },
    {
      meta: {
        productType,
        permission: [
          Permission["InternationalFlight:SalesReports:View"],
          Permission["InternationalFlight:Reports:View"],
        ],
      },
      path: "reports/reports-sale",
      name: productType + "reports-sale",
      component: () => import("@/views/report/report-sale/index.vue"),
    },
    {
      meta: {
        productType,
        permission: [
          Permission["InternationalFlight:PassengerReports:View"],
          Permission["InternationalFlight:Reports:View"],
        ],
      },
      path: "reports/reports-passenger",
      name: productType + "reports-passenger",
      component: () => import("@/views/report/report-passenger/index.vue"),
    },
    {
      meta: {
        productType,
        permission: [
          Permission["InternationalFlight:TicketReports:View"],
          Permission["InternationalFlight:Reports:View"],
        ],
      },
      path: "reports/reports-ticket",
      name: productType + "reports-ticket",
      component: () => import("@/views/report/report-ticket/index.vue"),
    },
    {
      meta: {
        productType,
        permission: Permission["InternationalFlight:SummeryOrders:View"],
      },
      path: "summary-orders",
      name: productType + "summary-orders",
      component: () =>
        import("@/views/international-flight/summary-orders/index.vue"),
    },
    {
      meta: {
        productType,
        permission: [
          Permission["InternationalFlight:Orders:View"],
          Permission["InternationalFlight:Ordsers:View"],
        ],
      },
      path: "orders",
      name: productType + "orders",
      component: () => import("@/views/international-flight/orders/index.vue"),
    },
    {
      meta: {
        productType,
        permission: [
          Permission["InternationalFlight:Orders:View"],
          Permission["InternationalFlight:Ordsers:View"],
        ],
      },
      path: "orders/detail/:orderId",
      name: productType + "orders-detail",
      component: () =>
        import("@/views/international-flight/orders/details/index.vue"),
    },
    {
      meta: {
        productType,
        permission: [
          Permission["InternationalFlight:ManualOrders:View"],
          Permission["InternationalFlight:Orders:CreateManual"],
        ],
      },
      path: "manual-order",
      name: productType + "manual-order",
      component: () =>
        import(
          "@/views/international-flight/manual-order/page/FlightManualOrderSubmission.vue"
        ),
    },
    {
      meta: {
        productType,
      },
      path: "chartered-agencies",
      name: productType + "chartered-agencies",
      component: () =>
        import(
          "@/views/international-flight/chartered-agencies/FlightCharteredAgencies.vue"
        ),
    },
    {
      meta: {
        productType,
      },
      path: "airlines",
      name: productType + "airlines",
      component: () =>
        import("@/views/international-flight/airlines/FlightAirlines.vue"),
    },
    {
      meta: {
        productType,
        permission: Permission["InternationalFlight:Providers:View"],
      },
      path: "providers",
      name: productType + "provider",
      component: () =>
        import("@/views/international-flight/provider/FlightProviders.vue"),
    },
    {
      meta: {
        productType,
        permission: Permission["InternationalFlight:ProviderTopUp:View"],
      },
      path: "provider-top-up",
      name: productType + "provider-top-up",
      component: () =>
        import(
          "@/views/international-flight/provider-top-up/ProviderTopUp.vue"
        ),
    },
    {
      path: "provider/:providerId/airlines",
      name: productType + "provider-airlines",
      meta: {
        productType,
        permission: Permission["InternationalFlight:Airlines:View"],
      },
      component: () =>
        import(
          "@/views/international-flight/provider/components/FlightProviderAssignedAirlines.vue"
        ),
    },
    {
      path: "baggage-setting",
      name: productType + "baggage-setting",
      meta: {
        productType,
        permission: Permission["InternationalFlight:BaggageConfig:View"],
      },
      component: () =>
        import(
          "@/views/international-flight/baggage-setting/FlightBaggageSetting.vue"
        ),
    },
    {
      meta: {
        productType,
        permission: Permission["InternationalFlight:Airports:View"],
      },
      path: "airports",
      name: productType + "airports",
      component: () =>
        import("@/views/international-flight/airports/FlightAirports.vue"),
    },
    {
      meta: {
        productType,
        permission: [
          Permission["InternationalFlight:PenaltyRules:View"],
          Permission["InternationalFlight:Crcn:View"],
        ],
      },
      path: "crcn",
      name: productType + "crcn",
      component: () =>
        import("@/views/international-flight/crcn/FlightCrcn.vue"),
    },

    {
      path: "route-rules",
      name: productType + "route-rules",
      meta: {
        productType,
        permission: [
          Permission["InternationalFlight:DestinationVisaRules:View"],
          Permission["InternationalFlight:RouteRulePolicy:View"],
        ],
      },
      component: () =>
        import(
          "@/views/international-flight/visa-direction/RouteRulePoliciesList.vue"
        ),
    },
    {
      path: "pricing",
      name: productType + "pricing",
      meta: {
        productType,
        permission: Permission["InternationalFlight:Pricings:View"],
      },
      component: () =>
        import("@/views/international-flight/pricing/FlightPricing.vue"),
    },
    {
      meta: {
        productType,
      },
      path: "pricing/config",
      name: productType + "create-price-config",
      component: () =>
        import(
          "@/views/international-flight/pricing/pages/FlightPricingConfig.vue"
        ),
    },
    {
      meta: {
        productType,
      },
      path: "pricing/config/:priceConfigId",
      name: productType + "edit-price-config",
      component: () =>
        import(
          "@/views/international-flight/pricing/pages/FlightPricingConfig.vue"
        ),
    },
    {
      meta: {
        productType,
      },
      path: "pricing/config/:priceConfigId/subconfig-list",
      name: productType + "subconfig-list",
      component: () =>
        import(
          "@/views/international-flight/pricing/pages/FlightPricingSubConfigList.vue"
        ),
    },
    {
      meta: {
        productType,
      },
      path: "pricing/config/:priceConfigId/create-subconfig",
      name: productType + "create-subconfig",
      component: () =>
        import(
          "@/views/international-flight/pricing/pages/FlightPricingSubConfigCreateEdit.vue"
        ),
    },
    {
      meta: {
        productType,
      },
      path: "pricing/config/:priceConfigId/update-subconfig/:subConfigId",
      name: productType + "update-subconfig",
      component: () =>
        import(
          "@/views/international-flight/pricing/pages/FlightPricingSubConfigCreateEdit.vue"
        ),
    },
    {
      path: "promotions",
      name: productType + "promotions",
      component: () =>
        import("@/views/international-flight/promotions/FlightPromotions.vue"),
    },
    {
      path: "rule-engine",
      name: productType + "rule-engine",
      meta: {
        productType,
        permission: [
          Permission["InternationalFlight:Filters:View"],
          Permission["InternationalFlight:RuleEngine:View"],
        ],
      },
      component: () =>
        import("@/views/international-flight/rule-engine/FlightRuleEngine.vue"),
    },

    // {
    //   path: "offline-issue-setting",
    //   name: productType + "offline-issue-setting",
    //   meta: {
    //     productType,
    //     permission: Permission["InternationalFlight:OfflineIssueSetting:View"],
    //   },
    //   component: () =>
    //     import(
    //       "@/views/international-flight/offline-issue-setting/FlightOfflineIssueSetting.vue"
    //     ),
    // },

    // {
    //   path: "refund",
    //   name: productType + "refund",
    //   meta: {
    //     productType,
    //     permission: Permission["InternationalFlight:Refund:View"],
    //   },
    //   component: () => import("@/views/international-flight/refund/index.vue"),
    // },
    {
      meta: {
        productType,
        permission: Permission["InternationalFlight:Refund:View"],
      },
      path: "refund/detail/:orderId",
      name: productType + "refund-detail",
      component: () =>
        import("@/views/international-flight/refund/detail/index.vue"),
    },

    // {
    //   path: "/accounting",
    //   name: "accounting",
    //   component: () => import("@/views/accounting/Index.vue"),
    //   children: [
    //     {
    //       path: "reports/b2b",
    //       name: "b2b",
    //       meta: {
    //         productType,
    //       },
    //       component: () => import("@/views/accounting/reports/b2b/Index.vue"),
    //     },
    //     {
    //       path: "reports/b2c",
    //       name: "b2c",
    //       meta: {
    //         productType,
    //       },
    //       component: () => import("@/views/accounting/reports/b2c/Index.vue"),
    //     },
    //     {
    //       path: "reports/deposit-withdraw",
    //       name: "deposit-withdraw",
    //       meta: {
    //         productType,
    //       },
    //       component: () =>
    //         import("@/views/accounting/reports/deposit-withdraw/Index.vue"),
    //     },
    //     {
    //       path: "reports/receipts",
    //       name: "receipts",
    //       meta: {
    //         productType,
    //       },
    //       component: () =>
    //         import("@/views/accounting/reports/receipts/Index.vue"),
    //     },
    //     {
    //       path: "reports/orders",
    //       name: "orders",
    //       meta: {
    //         productType,
    //       },
    //       component: () =>
    //         import("@/views/accounting/reports/orders/Index.vue"),
    //     },
    //     {
    //       path: "reports/passengers",
    //       name: "passengers",
    //       meta: {
    //         productType,

    //       },
    //       component: () =>
    //         import("@/views/accounting/reports/passengers/Index.vue"),
    //     },
    //   ],
    // },
  ];
}
export function getHotelRoutes() {
  return [
    {
      path: "/hotel/orders",
      name: "hotel-orders",
      meta: {
        permission: [Permission["Hotel:Orders:View"]],
      },
      component: () => import("@/views/hotel/orders/index.vue"),
    },
    {
      path: "/hotel/provider",
      name: "hotel-provider",
      meta: {
        permission: [Permission["Hotel:Providers:View"]],
      },
      component: () => import("@/views/hotel/provider/HotelProviders.vue"),
    },
    {
      path: "/hotel/pricing",
      name: "hotel-pricing",
      meta: {
        permission: [Permission["Hotel:Pricings:View"]],
      },
      component: () => import("@/views/hotel/pricing/HotelPricing.vue"),
    },
    {
      path: "/hotel/pricing/config",
      name: "create-price-config",
      meta: {
        permission: [Permission["Hotel:Pricings:View"]],
      },
      component: () =>
        import("@/views/hotel/pricing/pages/HotelPricingConfig.vue"),
    },
    {
      path: "/hotel/pricing/config/:hotelPriceConfigId",
      name: "edit-price-config",
      meta: {
        permission: [Permission["Hotel:Pricings:View"]],
      },
      component: () =>
        import("@/views/hotel/pricing/pages/HotelPricingConfig.vue"),
    },
    {
      path: "/hotel/hotel-list",
      meta: {
        permission: [Permission["Hotel:List:View"]],
      },
      name: "hotel-list",
      component: () => import("@/views/hotel/hotels/HotelList.vue"),
    },
    {
      path: "/hotel/manual-order/edit/:hotelId",
      meta: {
        permission: [Permission["Hotel:Orders:CreateManual"]],
      },
      name: "edit-hotel",
      component: () =>
        import(
          "@/views/hotel/manual-order/page/HotelManualOrderSubmission.vue"
        ),
    },
    {
      path: "/hotel/manual-order",
      name: "manual-order",
      meta: {
        permission: [Permission["Hotel:Orders:CreateManual"]],
      },
      component: () =>
        import(
          "@/views/hotel/manual-order/page/HotelManualOrderSubmission.vue"
        ),
    },
  ];
}

export function getTourRoutes() {
  return [
    {
      path: "/tour/tour-list",
      name: "tour-list",
      component: () => import("@/views/tour/tour-list/Index.vue"),
    },
    {
      path: "/tour/hotel-list",
      name: "tour-hotel-list",
      component: () => import("@/views/tour/hotel-list/Index.vue"),
    },
    {
      path: "/tour/order-list",
      name: "tour-order-list",
      component: () => import("@/views/tour/order-list/Index.vue"),
    },
    {
      path: "/tour/order-detail/:orderId",
      name: "tour-order-detail",
      component: () => import("@/views/tour/order-list/details/Index.vue"),
    },
  ];
}

export function getResellersRoutes() {
  return [
    {
      path: "/resellers/list",
      name: "resellers-list",
      meta: {
        permission: [Permission["TravelAgencies:List:View"]],
      },
      component: () => import("@/views/resellers/list/list.vue"),
    },
    {
      path: "/resellers/top-up",
      name: "resellers-top-up",
      meta: {
        permission: [Permission["TravelAgencies:TopUps:View"]],
      },
      component: () => import("@/views/resellers/top-up/top-up.vue"),
    },
    {
      path: "/resellers/transactions",
      name: "resellers-transactions",
      meta: {
        permission: [Permission["TravelAgencies:Transactions:View"]],
      },
      component: () =>
        import("@/views/resellers/transactions/transactions.vue"),
    },
  ];
}
